<template>
  <v-tooltip right color="transparent" v-if="fechamentoSemanal.length > 0">
    <template v-slot:activator="{ on, attrs }">
      <p v-bind="attrs" v-on="on" class="font-weight-bold primary--text">
        Ver Semanas Disponíveis
      </p>
    </template>
    <v-card color="white" class="pa-3">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th v-for="index in fechamentoSemanal.length" :key="index">
                Semana {{ index }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Data Inicial</th>
              <th
                class="font-weight-regular"
                v-for="(item, index) in fechamentoSemanal"
                :key="index"
              >
                {{ item.data_inicio | BrazilianStandardDate }}
              </th>
            </tr>
            <tr>
              <th>Data Final</th>
              <th
                class="font-weight-regular"
                v-for="(item, index) in fechamentoSemanal"
                :key="index"
              >
                {{ item.data_final | BrazilianStandardDate }}
              </th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-tooltip>
  <p v-else style="width: 99%;">
    <v-alert v-if="hasPermissionFechamento" color="warning" class="white--text">
      <v-icon color="white">mdi-alert</v-icon>
      Não há semanas de fechamento disponíveis para criar uma campanha! Clique
      <a
        href="/parametros/tipo-fechamento"
        target="_self"
        class="link-tipo-fechamento"
        >aqui</a
      >
      para cadastrar fechamemtos.
    </v-alert>
    <v-alert v-else color="warning" class="white--text">
      <v-icon color="white">mdi-alert</v-icon>
      Não há semanas de fechamento disponíveis para criar uma campanha! Entre em
      contato com a gestão comercial
    </v-alert>
  </p>
</template>

<script>
import dateMixin from "@/mixins/dateMixin.js";
import { mapGetters } from "vuex";
export default {
  name: "TableFechamentoSemanais",

  props: {
    fechamentoSemanal: {
      type: Array
    }
  },

  mixins: [dateMixin],

  data() {
    return {
      hasPermissionFechamento: true
    };
  },

  computed: {
    ...mapGetters({
      getUsers: "getUsers"
    })
  },

  methods: {
    verificaNivel() {
      if (this.getUsers.nivel != "1" && this.getUsers.nivel != "2") {
        this.hasPermissionFechamento = false;
      }
    }
  },

  mounted() {
    this.verificaNivel();
  }
};
</script>

<style>
.link-tipo-fechamento {
  color: purple !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
</style>
